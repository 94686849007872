import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  footerContainer: {
    backgroundColor: '#f8f8f8',
    padding: '20px 0',
    '& .container': {
      padding: theme.spacing(0, 1.25),
      maxWidth: '1170px',
      ['@media(min-width: 1370px)']: {
        maxWidth: '1280px'
      }
    },
    '& h6, & h5': {
      fontSize: '20px',
      fontWeight: '500',
      color: '#30343e',
      margin: '0',
      padding: '0'
    },
    '& h5': {
      marginBottom: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      '& h5': {
        marginBottom: '10px'
      },
      '& .menu-items': {
        marginBottom: '20px'
      },
      '& .follow-us-wrapper': {
        marginBottom: '30px'
      }
    }
  },
  headingText: {
    fontSize: '24px',
    fontWeight: 500,
    marginBottom: '10px'
  }
}));
const allContent = [
  {
    heading: 'Check Vehicle History',
    content:
      'Vehicle History is a platform that provides users with overall information about the vehicle. It helps users to do a vehicle history check of any car, bike or scooter and get the vehicle history report. The report generated by History helps you check vehicle history online. This full vehicle history report is very useful for the buyers as well as sellers as it helps the users by providing all the details which are crucial while buying or selling a used automobile. History Tool lets the user know about the background of any used vehicle by providing vehicle information.'
  },
  {
    heading: 'What are the Benefits to Check Vehicle History?',
    content:
      'History is very beneficial to any user who is looking to buy or sell a used vehicle. The data provided by vehicle history reports helps buyers to make a more informed decision when buying a used vehicle. One can check the vehicle details and background of any vehicle online through a vehicle history report, which hardly takes 10 seconds to generate. This gives quick and easy access to any kind of vehicle information registered in India. The authentic data that one gets through the vehicle history report builds confidence in the buying decision of a used vehicle.'
  },
  {
    heading: 'How to Check Vehicle History Online?',
    content:
      'A used vehicle may have hidden surprises, so checking its past is essential. You can check vehicle history online through online portals. Simply enter the vehicle’s registration number to access details like past ownership, accident reports, insurance claims, and service history. This process helps buyers make informed decisions and avoid fraud. Always rely on authorized sources for accurate and up-to-date information, ensuring a secure and hassle-free vehicle purchase.'
  },
  {
    heading: 'How to Check Vehicle Owner Details?',
    content:
      "Verifying a vehicle’s owner is crucial, whether you're buying a second-hand vehicle or handling legal matters. With just the registration number, you can check vehicle owner details through online portals. This reveals essential information such as the owner’s name, address, and registration validity. Using authorized platforms ensures privacy protection and accurate data. Always confirm details from reliable sources to avoid fraud and ensure a smooth transaction."
  },
  {
    heading: 'How to check RTO Vehicle information?',
    content:
      'Understanding a vehicle’s official records is now easier than ever. You can check RTO information using online portals or mobile apps. By entering the vehicle’s registration number, you gain access to details such as registration status, owner name, insurance validity, and road tax information. This helps in verifying the authenticity of a vehicle before purchase. Always choose trusted sources to ensure accurate and legally verified data.'
  },
  {
    heading: 'How to Check Vehicle Information Online?',
    content:
      "Before making any vehicle-related decisions, verifying its details is a smart move. In order to check vehicle information online, reliable portals are used, which provide insights into the make, model, fuel type, registration status, and insurance validity by entering the registration number. It’s a quick and convenient way to confirm a vehicle’s authenticity, whether you're buying, selling, or ensuring compliance with legal requirements."
  },
  {
    heading: 'How to Check Vehicle Registration Details?',
    content:
      'Ensuring a vehicle’s registration is valid can save you from legal troubles and fraud. The only way to check registration details is through online portals by entering the vehicle’s registration number. This allows access to essential information like ownership details, registration date, fuel type, and tax status. Always verify registration details from trusted sources to ensure accuracy and secure transactions.'
  },
  {
    heading: 'Why Choose Vehicle History?',
    content:
      'History is an unbiased and accurate comprehensive report that reveals overall vehicle information online regarding any vehicle’s background. The vehicle information includes details such as vehicle loan history, vehicle owner details, vehicle fitness report validity, vehicle registration details, vehicle age, blacklisted vehicle, the number of times the vehicle is resold, vehicle accident history, etc. In this way, you get to know all the necessary details about the vehicle you are planning to buy. The users need to provide the vehicle registration number for a vehicle history check to receive a detailed used vehicle report.'
  },
  {
    heading: 'Vehicle History Methodology',
    content:
      'Being the most advanced and comprehensive used vehicle history checker, History is based on true facts and provides the most genuine vehicle history report in India. This is the most authentic way to check vehicle registration details, as History takes the data from RTO vehicle information. Regional Transport Office (RTO) is the only Government of India organization that is authorized to maintain a database of vehicles for all states and union territories and provide reliable vehicle info in India.  If you are planning to buy a used vehicle, you need to be well-informed about its background, and this is where Droom Vehicle History comes in. It provides an unbiased history report and gives vehicle information online about its past. History takes the used vehicle registration number as input and checks vehicle information by number to generate a valid report for that vehicle. This way, you can check the entire history of any used vehicle and get a free vehicle history report and accident history report.'
  }
];
const FooterContent = () => {
  const classes = useStyles();
  return (
    <div
      className={classes.footerContainer}
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <div className="container">
        {allContent.map((item, index) => (
          <Fragment key={index}>
            <h4 className={classes.headingText}>{item.heading}</h4>
            <p>{item.content}</p>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default FooterContent;
